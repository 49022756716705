<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="grid1"
          title="생산 직접부서"
          :merge="grid1.merge"
          tableId="grid1"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :columns="grid1.columns"
          :data="grid1.data"
          :isExcelDown="false"
          selection="multiple"
          rowKey="deptCd"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="생산직접부서 추가" icon="add" @btnClicked="addDept1" v-if="editable" />
              <c-btn label="제외" icon="remove" @btnClicked="removeDept1" v-if="editable" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList1" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="grid2"
          title="일반 대상부서"
          :merge="grid2.merge"
          tableId="grid2"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :columns="grid2.columns"
          :data="grid2.data"
          :isExcelDown="false"
          selection="multiple"
          rowKey="deptCd"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="일반대상부서 추가" icon="add" @btnClicked="addDept2" v-if="editable" />
              <c-btn label="제외" icon="remove" @btnClicked="removeDept2" v-if="editable" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList2" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'uninjury-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        increaseFlag: 'N',
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid1: {
        merge: [
          { index: 0, colName: 'upDeptCd' },
        ],
        columns: [
          {
            name: 'upDeptName',
            field: 'upDeptName',
            label: '상위부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: 'LBLDEPT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'increaseFlagName',
            field: 'increaseFlagName',
            label: '무재해대상',
            align: 'center',
            sortable: false,
          },
        ],
        data: []
      },
      grid2: {
        merge: [
          { index: 0, colName: 'upDeptCd' },
        ],
        columns: [
          {
            name: 'upDeptName',
            field: 'upDeptName',
            label: '상위부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: 'LBLDEPT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'increaseFlagName',
            field: 'increaseFlagName',
            label: '무재해대상',
            align: 'center',
            sortable: false,
          },
        ],
        data: []
      },
      editable: true,
      listUrl: '',
      updateUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.dept.list.url;
      this.updateUrl = transactionConfig.mdm.dept.update.url + 'increase'
      this.getList();
    },
    getList() {
      this.getList1();
      this.getList2();
    },
    getList1() {
      this.searchParam.increaseFlag = 'Y';
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },);
    },
    addDept1() {
      this.popupOptions.title = '생산 직접부서 추가';
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDept1;
    },
    closeDept1(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid1.data, { deptCd: item.deptCd }) === -1 && this.$_.findIndex(this.grid2.data, { deptCd: item.deptCd }) === -1) {
            saveData.push({
              increaseFlag: 'Y',
              deptCd: item.deptCd,
            });
          }
        });

        this.$http.url = this.updateUrl;
        this.$http.type = 'PUT';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getList1();
        });
      }
    },
    getList2() {
      this.searchParam.increaseFlag = 'X';
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);
    },
    addDept2() {
      this.popupOptions.title = '일반 대상부서 추가';
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDept2;
    },
    closeDept2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid1.data, { deptCd: item.deptCd }) === -1 && this.$_.findIndex(this.grid2.data, { deptCd: item.deptCd }) === -1) {
            saveData.push({
              increaseFlag: 'X',
              deptCd: item.deptCd,
            });
          }
        });

        this.$http.url = this.updateUrl;
        this.$http.type = 'PUT';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getList2();
        });
      }
    },
    removeDept1() {
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '무재해 대상에서 제거하시겠습니까?', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let saveData = [];
            this.$_.forEach(selectData, item => {
              saveData.push({
                increaseFlag: 'N',
                deptCd: item.deptCd,
              });
            });

            this.$http.url = this.updateUrl;
            this.$http.type = 'PUT';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList1();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    removeDept2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '무재해 대상에서 제거하시겠습니까?', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let saveData = [];
            this.$_.forEach(selectData, item => {
              saveData.push({
                increaseFlag: 'N',
                deptCd: item.deptCd,
              });
            });

            this.$http.url = this.updateUrl;
            this.$http.type = 'PUT';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList2();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
<style scoped>
.uninjury-addyear {
  margin-top: 15px;
}
</style>